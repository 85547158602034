<template>
  <div>
    <div class="hero">
      <div class="hero__grid hero__grid--1">
        <img svg-inline alt="logo" src="@/assets/img/about/photo-1.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-2.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-3.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-4.png" />
      </div>
      <section class="hero__details">
        <h4>ABOUT US</h4>
        <h2>
          LawPavilion is the Nigerian #1 LegalTech company with solutions for
          Lawyers, Law Firms e.t.c
        </h2>
        <p>
          We exist to help Lawyers, Judges and Law firms make the most of their
          legal practice through cutting-edge IT solutions.
        </p>
      </section>
      <div class="hero__grid hero__grid--2">
        <img svg-inline alt="logo" src="@/assets/img/about/photo-5.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-6.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-7.png" />
        <img svg-inline alt="logo" src="@/assets/img/about/photo-8.png" />
      </div>
    </div>

    <div class="company">
      <!-- mission -->
      <div class="company__mission">
        <div class="company__mission--left">
          <h3>OUR MISSION</h3>
          <h2>Accelerating access to Justice through Technology</h2>

          <p>
            LawPavilion’s mission is to create a workplace where work empowers
            people. Winning companies put people first. If you focus on
            recruiting, cultivating, and retaining good people, you’ll see
            better results. This is the fundamental belief in LawPavilion.
          </p>
          <router-link to="/careers"
            >Join our Team
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 13h16.17l-5.59 5.59L12 20l8-8-8-8-1.41 1.41L16.17 11H0v2z" fill="#FFA141"/></svg>
          </router-link>
        </div>
        <div class="company__mission--right">
          <img
            class="mission__img"
            svg-inline
            alt="logo"
            src="@/assets/img/about/mission1.png"
          />
          <img
            class="mission__img"
            svg-inline
            alt="logo"
            src="@/assets/img/about/mission2.png"
          />
          <img
            class="mission__img"
            svg-inline
            alt="logo"
            src="@/assets/img/about/mission3.png"
          />
        </div>
      </div>
      <!-- vision -->
      <div class="company__vision">
        <div class="company__vision--left">
          <img
            class="vision__img"
            svg-inline
            alt="logo"
            src="@/assets/img/about/vision.png"
          />
        </div>
        <div class="company__vision--right">
          <h3>OUR VISION</h3>
          <h2>
            To be the No. 1 African Provider of Legal Tech and Artificial
            Intelligence Solutions
          </h2>

          <p>
            LawPavilion’s mission is to create a workplace where work empowers
            people. Winning companies put people first. If you focus on
            recruiting, cultivating, and retaining good people, you’ll see
            better results.
          </p>
          <router-link to="/careers"
            >Join our Team
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 13h16.17l-5.59 5.59L12 20l8-8-8-8-1.41 1.41L16.17 11H0v2z" fill="#FFA141"/></svg>
          </router-link>
        </div>
      </div>
    </div>

    <div class="director">
      <div class="director__grid">
        <div class="director__grid--left">
          <h2>
            "As we grew, LawPavilion made me feel closer to my people than I did
            when we could still all fit into one room."
          </h2>
          <p>Ope Olugasa, CEO</p>
        </div>

        <div class="director__grid--right">
          <section>
            <div>
              <img svg-inline alt="logo" src="@/assets/img/about/ope.png" />
            </div>
          </section>
        </div>
      </div>
    </div>

    <section class="value">
      <div class="value__container">
        <h4 class="value__title--main">Values We Respect</h4>
        <div class="value__list">
          <div
            class="value__list-item"
            :key="index"
            v-for="(value, index) in values"
          >
            <p class="value__list-item--no">VALUE NO.{{ index + 1 }}</p>
            <p class="value__list-item--title">{{ value.title }}</p>
            <p class="value__list-item--content">{{ value.content }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    const values = [
      {
        icon: "image",
        title: "Passion ❤️",
        content:
          "We shoulder the happiness of our employees and clients by treating everyone with dignity.",
      },
      {
        icon: "image",
        title: "Innovation 💡",
        content:
          "We are just like trees in the forest, our professional and personal growth is fast thanks to positive competition.",
      },
      {
        icon: "image",
        title: "Customer Satisfaction ☺️",
        content:
          "We never leave our teammates to address a challenge by their own. We help each other and act as a team.",
      },
      {
        icon: "image",
        title: "Quality 👍🏾",
        content:
          "With the team and the clients. We speak about the objectives and expectations. ",
      },
      {
        icon: "image",
        title: "Integrity🥇",
        content:
          "We defeat the chaos through well-regulated and tailored project execution processes.",
      },
      {
        icon: "image",
        title: "Timeliness ⌚️  ",
        content:
          "Our value is team’s knowledge and experience. We deliver full-service webdesign and don’t accept.",
      },
      {
        icon: "image",
        title: "Team🤝",
        content:
          "We shoulder the happiness of our employees and clients by treating everyone with dignity.",
      },
    ];
    return {
      values,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./About.component.scss";
</style>
